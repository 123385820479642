import React, { Component } from 'react';
import { connect } from 'react-redux';
import { register } from 'store/actions/auth';
import { addMessage } from 'store/actions/messages';
import { Form, initialize, reduxForm, SubmissionError } from 'redux-form';
import { Field } from 'redux-form';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import './styles.css';
import { Box, Button, Heading, Layer } from 'grommet';
import { Checkmark, Close } from 'grommet-icons';
import * as moment from 'moment-timezone';

import { 
  renderCheckBox, 
  renderFormField, 
  renderSelectField,
} from 'helpers/FormFields';

import ageGroups from 'helpers/Filter/ageGroups';
import transgenders from 'helpers/Filter/transgenders';
import ethnicities from 'helpers/Filter/ethnicities';
import genders from 'helpers/Filter/genders';
import householdIncomes from 'helpers/Filter/householdIncomes';
import levelsOfEducation from 'helpers/Filter/levelsOfEducation';
import technologyAccesses from 'helpers/Filter/technologyAccesses';
import locations from 'helpers/Filter/locations';

const handlers = {
  'click': null,
  'scroll': null,
  'touchstart': null,
  'touchend': null,
  'touchmove': null,
  'touchcancel': null,
  'keydown': null,
  'keyup': null,
};

class Registration extends Component {

  constructor(props){
    super(props);
    this.state = {
      autoClose: null,
    };
  }

  componentDidMount() {
    
    // setup events to trigger still here
    this.setupAutoClose();
    const handler = e => this.setupAutoClose();
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] = handler;
      window.addEventListener(eventName, handler, true);
    });
  }

  componentWillUnmount() {
    
    if (this.state.autoClose!==null) {
      clearTimeout(this.state.autoClose);
    }

    // remove events that trigger still here
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] && window.removeEventListener(eventName, handlers[eventName], true);
    });
  }

  setupAutoClose = () => {

    if (this.state.autoClose!==null) {
      clearTimeout(this.state.autoClose);
    }

    const autoClose = setTimeout(() => {
      this.resetAndClose();
    }, 60000); // 60 seconds
    this.setState({ autoClose });
  }

  resetAndClose = () => {
    const { resetRegistration, toggle } = this.props;
    resetRegistration();
    toggle(false);
  }

  submitForm = values => {

    const {
      handleMessage,
      // storeRegistration,
    } = this.props;
    
    const auth = {...values.auth};
    const contact = {...values.contact};
    const { contact_image } = values;

    /**
     * Validate
     */

    const errors = {};
    const authErrors = {};
    const contactErrors = {};

    // Account info

    if (!auth.username) 
      authErrors.username = 'Required';

    if (!auth.password) 
      authErrors.password = 'Required';
    else if (
      auth.password.length<8 ||
      !auth.password.match(/[a-z]+/) ||
      !auth.password.match(/[A-Z]+/) ||
      !auth.password.match(/[0-9]+/)
    )
      authErrors.password = 'Does not match requirements';

    if (!auth.password_confirmation) 
      authErrors.password_confirmation = 'Required';

    if (auth.password!==auth.password_confirmation)
      authErrors.password_confirmation = 'Passwords must match';

    // Profile

    if (!contact.first_name)
      contactErrors.first_name = 'Required';
  
    if (!contact.last_name)
      contactErrors.last_name = 'Required';
  
    // if (!contact.street_address)
    //   contactErrors.street_address = 'Required';
  
    // if (!contact.city)
    //   contactErrors.city = 'Required';
    
    // if (!contact.state_province_id)
    //   contactErrors.state_province_id = 'Required';
    
    // if (!contact.postal_code)
    //   contactErrors.postal_code = 'Required';
  
    // if (!contact.phone)
    //   contactErrors.phone = 'Required';
    // else if (!contact.phone.match(/^\(\d{3}\)\s\d{3}-\d{4}$/))
    //   contactErrors.phone = 'Invalid phone';

    // if (!contact.phone_type_id)
    //   contactErrors.phone_type_id = 'Required';
  
    // if (!contact.email)
    //   contactErrors.email = 'Required';
    // else if (contact.email.indexOf('@')<0)
    //   contactErrors.email = 'Invalid email';
  
    // if (!contact.birth_date) {
    //   contactErrors.birth_date = 'Required';
    // } else {
    //   const birth_date_local = moment(contact.birth_date); // consume in in local timezone
    //   const birth_date = moment.tz(birth_date_local.format('YYYY-MM-DD'), 'YYYY-MM-DD', 'America/Los_Angeles'); // flip to la timezone to prevent accidentally switching days
    //   const fifty_years_ago = moment().tz("America/Los_Angeles").subtract(50, 'year').startOf('day');
    //   // console.log(birth_date.toISOString(), fifty_years_ago.toISOString())
    //   if (birth_date.isSameOrAfter(fifty_years_ago))
    //     contactErrors.birth_date = "Thank you for your interest in Senior Services. Participants must be age 50 and older to enroll in our program. Please call us at 323-860-5830 if you have any questions about our program and age requirements.";
    // }
  
 
    if (!contact.gender_id) // gender
      contactErrors.gender_id = 'Required';
  
    if (!contact.custom_151) // transgender
      contactErrors.custom_151 = 'Required';
  
    if (!contact.custom_144) // ethnicity
      contactErrors.custom_144 = 'Required';
  
    // if (!contact.custom_1212 || (Array.isArray(contact.custom_1212) && contact.custom_1212.length===0)) // race
    //   contactErrors.custom_1212 = 'Required';

    if (!contact.custom_142) // age group
      contactErrors.custom_142 = 'Required';
  
    if (!contact.custom_145) // level of education
      contactErrors.custom_145 = 'Required';
  
    if (!contact.custom_146) // household income
      contactErrors.custom_146 = 'Required';
    
    /**
     * Aggrigate errors
     */
    const errorKeys = Object.keys(errors);
    const authErrorKeys = Object.keys(authErrors);
    const contactErrorsKyes = Object.keys(contactErrors);
    if (errorKeys.length>0 || authErrorKeys.length>0 || contactErrorsKyes.length>0) {
      handleMessage('Please fill out all required information.', 'error');
      throw new SubmissionError({
        ...errors,
        auth: authErrors,
        contact: contactErrors,
      });
      // throw new SubmissionError({
      //   _error: errorKeys.map(key => errors[key]).join(', '),
      // });
    }

    
    /**
     * Add in updated times
     */

    const now = moment().tz('America/Los_Angeles');
    const format = 'YYYY-MM-DD';

    // Last updated
    contact.custom_149 = now.format(format);

    // Last updated location
    contact.custom_152 = process.env.REACT_APP_KIOSK_LOCATION;

    /**
     * Modify some data that needed handled differently in checkboxes
     */

    const { custom_147 } = contact;
    contact.custom_147 = custom_147 ? custom_147.map((v,i) => v ? i : false).filter(v => v!==false) : [];

    /**
     * Save
     */

    const json = {
      auth,
      contact,
    };

    const formData = new FormData();
    formData.append('json', JSON.stringify(json));

    // I wonder if we could use the webcam to capture this
    if (contact_image && contact_image[0]) {
      formData.append('contact_image', contact_image[0]);
    }
  
    const url = handleCiviURLBuild('Account', 'create');

    return fetch(url, {
      method: "POST",
      body: formData,
    })
    .then(response => response.json())
    .then(json => {
      // console.log(json);
      if (json.is_error) {
       throw new Error(json.error_message);
      }
      handleMessage('Successfully created account. You can now login.', 'success');
      return json;
    })
    // .then(json => handleLogin(auth.username, auth.password))
    // .then(json => storeRegistration(json.contact_id)) // cant do this because we are not logged in
    .then(() => this.resetAndClose())
    .catch(error => {
      handleMessage(`There was an issue creating your account (${error.message})`, 'error');
      return false;
    });

  }

  render() {

    const { open } = this.props;

    if (!open)
      return null;

    const { handleSubmit, pristine, submitting } = this.props;
    const location = locations.find(i => i.value===process.env.REACT_APP_KIOSK_LOCATION);

    return (
      <Layer 
        className="Registration"
        full={true}
        margin="large"
        model={true}
        onClickOutside={() => this.resetAndClose()}
        onEsc={() => this.resetAndClose()}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>
                Get Started Now
              </Heading>
            </Box>
            <Box>
              <Button icon={<Close />} label="Close" onClick={() => this.resetAndClose()} />
            </Box>
          </Box>
          <Box fill style={{ padding: '24px', overflowY: 'auto' }}>
            <Form onSubmit={handleSubmit(this.submitForm)}>
              <p><em>
                We work with many funding sources to provide free and low cost 
                services including the {location ? location.label : 'Unknown'} CyberCenter. As part of our 
                agreement with the funcing sources, we must collect some basic
                demographic information. All responses are confidential.
              </em></p>
              <h3>Account Information</h3> 
              <Box direction="row-responsive">
                <Box basis="1/3" pad="small">
                  <Field
                    label="First Name *"
                    id="first_name" 
                    name="contact[first_name]" 
                    component={renderFormField}
                  />
                </Box>
                <Box basis="1/3" pad="small">
                  <Field
                    label="Last Name *"
                    id="last_name" 
                    name="contact[last_name]" 
                    component={renderFormField}
                  />
                </Box>
                <Box basis="1/3" pad="small">
                  <Field
                    label="Email"
                    id="email" 
                    name="contact[email]" 
                    component={renderFormField}
                  />
                </Box>
              </Box>
              <Box direction="row-responsive">
                <Box basis="1/3" pad="small">
                  <Field
                    label="Username"
                    id="username" 
                    name="auth[username]" 
                    // disabled={isFetching}
                    component={renderFormField}
                  />
                </Box>
                <Box basis="1/3" pad="small">
                  <Field
                    label="Password"
                    id="password" 
                    name="auth[password]" 
                    // disabled={isFetching}
                    type="password"
                    component={renderFormField}
                  />
                  <small>8 character minimum with an upper and lower case letter and a number</small>
                </Box>
                <Box basis="1/3" pad="small">
                  <Field
                    label="Confirm Password"
                    id="password_confirmation" 
                    name="auth[password_confirmation]" 
                    // disabled={isFetching}
                    type="password"
                    component={renderFormField}
                  />
                </Box>
              </Box>
              <h3>Demographics</h3>
              <Box direction="row-responsive">
                <Box basis="1/3" pad="small">
                  <Field
                    label="Gender *"
                    id="gender_id" 
                    name="contact[gender_id]" 
                    // disabled={isFetching}
                    component={renderSelectField}
                    options={genders}
                  />
                </Box>
                <Box basis="1/3" pad="small">
                  <Field
                    label="Transgender *"
                    id="custom_151" 
                    name="contact[custom_151]" 
                    // disabled={isFetching}
                    component={renderSelectField}
                    options={transgenders}
                  />
                </Box>
                <Box basis="1/3" pad="small">
                  <Field
                    label="Ethnicity *"
                    id="custom_144" 
                    name="contact[custom_144]" 
                    // disabled={isFetching}
                    component={renderSelectField}
                    options={ethnicities}
                  />
                </Box>
              </Box>
              <Box direction="row-responsive">
                <Box basis="1/3" pad="small">
                  <Field
                    label="Age Group *"
                    id="custom_142" 
                    name="contact[custom_142]" 
                    // disabled={isFetching}
                    component={renderSelectField}
                    options={ageGroups}
                  />
                </Box>
                <Box basis="1/3" pad="small">
                  <Field
                    label="Education Level *"
                    id="custom_145" 
                    name="contact[custom_145]" 
                    // disabled={isFetching}
                    component={renderSelectField}
                    options={levelsOfEducation}
                  />
                </Box>
                <Box basis="1/3" pad="small">
                  <Field
                    label="Household Income *"
                    id="custom_146" 
                    name="contact[custom_146]" 
                    // disabled={isFetching}
                    component={renderSelectField}
                    options={householdIncomes}
                  />
                </Box>
              </Box>
              <Box direction="row-responsive">
                <Box basis="1/3" pad="small">
                  Check all that apply
                </Box>
                {technologyAccesses.map(option => (
                  <Box basis="1/3" pad="small" key={option.value}>
                    <Field
                      label={`${option.label}`}
                      id={`custom_147_${option.value}`} 
                      name={`contact[custom_147][${option.value}`}
                      // disabled={isFetching}
                      component={renderCheckBox}
                    />
                  </Box>
                ))}
              </Box>
              <hr />
              <div className="submit-buttons">
                <Button
                  icon={<Checkmark />}
                  label="Register"
                  type="submit"
                  primary={true}
                  color="neutral-1"
                  disabled={pristine || submitting}
                />
              </div>
            </Form>
          </Box>
        </Box>
      </Layer>
    );
  }
}

const formName = 'registration';

const initialValues = {
  auth: {
  },
  contact: {
    contact_type: "Individual",
    contact_sub_type: "Cyber_Center",
  },
};

const mapStateToProps = (state) => {

  const { form } = state;
  
  const formLoaded = formName in form && 'values' in form[formName];
  const formData = formLoaded && form[formName].values;

  return {
    formLoaded,
    formData,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleMessage: (message, variant=null, undo=null) => dispatch(addMessage(message, variant, undo)),

    initializeRegistration: data => dispatch(initialize(formName, data)),
    resetRegistration: () => dispatch(initialize(formName, initialValues)),

    storeRegistration: contact_id => dispatch(register(contact_id)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues,
  })(Registration)
);