const ageGroups = [
  {
    label: 'Under 18',
    value: '1',
  },
  {
    label: '18-29',
    value: '2',
  },
  {
    label: '30-50',
    value: '3',
  },
  {
    label: '50+',
    value: '4',
  },
  {
    label: 'Prefer not to disclose',
    value: '5',
  },
];

export default ageGroups;

// SELECT
// GROUP_CONCAT(
//     CONCAT(
//         "  {\n",
//             "    label: '", label, "',\n"
//             "    value: '", `value`, "',\n"
//         '  },'
//     )
//     SEPARATOR
//     "\n"
// )
// FROM `civicrm_option_value`
// WHERE option_group_id=136