import React, { Component } from 'react';
import { Box, Button, Heading, Layer } from 'grommet';
import { Close } from 'grommet-icons';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  // Marker,
} from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

import locations from 'helpers/Filter/locations';
import LocationSelect from './LocationSelect';

const MapComponent = withScriptjs(withGoogleMap((props) => {

  const { center, handleCenterChange, handleZoomChange, markers, zoom } = props;

  const mapRef = React.createRef();

  return (
    <GoogleMap
      // defaultZoom={13}
      // defaultCenter={{ lat: 34.100552, lng: -118.332732 }}
      onZoomChanged={() => handleZoomChange(mapRef.current.getZoom())}
      onCenterChanged={() => handleCenterChange(mapRef.current.getCenter())}
      zoom={zoom}
      center={center}
      ref={mapRef}
    >
      {markers.map((v,i) => (
        <MarkerWithLabel 
          key={i}
          position={v.position}
          // onClick={e => console.log(e)}
          labelAnchor={{ x: 0, y: 105 }}
          labelStyle={{
            fontSize: '24px',
            transform: 'translateX(-50%)',
          }}
        >
          <Box
            background="light-1"
            pad="small"
            round="small"
            border={{
              side: "all",
              size: "small",
              color: "dark-3",
            }}
          >
            {v.label}
          </Box>
        </MarkerWithLabel>
      ))}
    </GoogleMap>
  );
}));

class MapDisplay extends Component {

  mapRef = React.createRef();

  constructor(props){
    super(props);

    const selectedLocations = locations.map((v,i) => v.selected ? i : null).filter(v => v!==null);
    const center = selectedLocations.length>0 ? locations[selectedLocations[0]].position : { lat: 34.100552, lng: -118.332732 };

    this.state = {
      center,
      zoom: 12,
      selectedLocations,
    };
  }

  render() {

    const { open, toggle } = this.props;
    const { center, zoom, selectedLocations } = this.state;

    if (!open) return null;

    return (
      <Layer 
        className="MapDisplay"
        full={true}
        margin="large"
        model={true}
        onClickOutside={() => toggle(false)}
        onEsc={() => toggle(false)}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>Location Map</Heading>
            </Box>
            <Box style={{ overflowY: 'auto' }}>
              <Button
                icon={<Close />}
                margin="small"
                label="Close"
                type="submit"
                onClick={() => toggle(false)}
              />
            </Box>
          </Box>
          <Box 
            className="Login"
            direction='row' 
            flex 
            overflow={{ horizontal: 'hidden' }}
          >
            <Box 
              flex
              basis="1/2"
              style={{
                overflowY: 'auto',
              }}
            >
              <LocationSelect
                selected={selectedLocations}
                locations={locations}
                handleChange={e => {
                  /**
                   * CheckBox
                   */
                  // const i = parseInt(e.target.name.split('_')[1]);
                  // this.setState({ selectedLocations: selectedLocations.includes(i) ? selectedLocations.filter(v => v!==i) : [].concat(selectedLocations,i)})
                  /**
                   * RadioButton
                   */
                  this.setState({ 
                    selectedLocations: [e],
                    center: locations[e].position,
                  });
                }}
              />
            </Box>
            <Box flex basis="1/2">
              <MapComponent
                // googleMapURL="https://www.google.com/maps/embed/v1/place?q=1625+N.+Schrader+Blvd.+%0D%0ALos+Angeles%2C+CA+90028-6213&key=AIzaSyDWvMJSOuVnUXpq3BNWXu5Xf17VoCzPtqM"
                googleMapURL={"https://maps.googleapis.com/maps/api/js?v=3&key="+process.env.REACT_APP_GOOGLE_API_KEY}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                markers={locations.filter((v,i) => selectedLocations.includes(i))}
                center={center}
                zoom={zoom}
                handleCenterChange={newCenter => this.setState({ center: newCenter})}
                handleZoomChange={newZoom => this.setState({ zoom: newZoom })}
              />
            </Box>
          </Box>
        </Box>
      </Layer>
    );
  }
}

export default MapDisplay;