import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Heading } from 'grommet';
import { UserNew } from 'grommet-icons';
import './Auth/styles.css';

import Barcode from './Auth/Barcode';
import UsernamePassword from './Auth/UsernamePassword';
import Registration from './Auth/Registration';

import locations from 'helpers/Filter/locations';

class Auth extends Component {

  constructor(props){
    super(props);
    this.state = {
      registrationOpen: false,
    };
  }
  
  render() {

    const { registrationOpen } = this.state;
    const location = locations.find(i => i.value===process.env.REACT_APP_KIOSK_LOCATION);
    
    return (
      <Box className="Auth" direction="row-responsive" wrap={true} fill={true}>
        <Box pad="medium" basis="1/4" className="welcome" justify="start">
          <Heading level="1" margin={{ top: "none", left: "none", right: "none", bottom: "medium", }}>
            Welcome to {location ? location.label : 'Unknown'}
          </Heading>
          <Heading level="3" margin="none">
            Use this kiosk to check into the CyberCenter.
          </Heading>
        </Box>
        <Box className="actions" flex basis="3/4" justify="start" pad={{ top: "large", bottom: "none", left: "large", right: "large" }}>
          <Box className="new" pad="medium" round="small">
            <Heading level="2" margin="none">
              New to the CyberCenter?
              <Button
                icon={<UserNew />}
                margin="small"
                label="Sign Up"
                type="submit"
                primary={true}
                color="neutral-1"
                onClick={() => this.setState({ registrationOpen: true })}
              />
            </Heading>
          </Box>
          <Box pad="none">
            <Box className="auth-options" flex direction='row-responsive' justify="center">
              <Box pad={{ vertical: "medium" }} flex="grow" basis="2">
                <Box className="auth-option" pad="small" round="small">
                  <Heading margin={{ top: "small", bottom: "small", left: "none", right: "none" }}>
                    Login
                  </Heading>
                  <Heading level="4" margin={{ top: "small", bottom: "medium", left: "none", right: "none" }}>
                    Use your username and password.
                  </Heading>
                  <UsernamePassword />
                </Box>
              </Box>
              <Box pad="medium" basis="1">
                <Box className="or-box" direction="column" justify="center" align="center" pad={{ vertical: "small" }}>
                  <Box className="or-line" border={{ size: "small", color: "dark-1" }} flex="grow"></Box>
                  <Box pad={{ vertical: "medium" }} >OR</Box>
                  <Box className="or-line" border={{ size: "small", color: "dark-1" }} flex="grow"></Box>
                </Box>
              </Box>
              <Box pad={{ vertical: "medium" }} flex="grow" basis="2">
                <Box className="auth-option" pad="small" round="small">
                  <Heading margin={{ top: "small", left: "none", right: "none", bottom: "medium" }}>
                    Scan ID Card
                  </Heading>
                  <Barcode />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Registration open={registrationOpen} toggle={newState => this.setState({ registrationOpen: newState })} />
      </Box>
    );
  }
}


const mapStateToProps = (state) => {
  const { display: { mobileMode }} = state;
  return {
    mobileMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
