const transgenders = [
  {
    label: 'Yes',
    value: '1',
  },
  {
    label: 'No',
    value: '2',
  },
  {
    label: 'Prefer not to disclose',
    value: '3',
  },
];

export default transgenders;

// SELECT
// GROUP_CONCAT(
//     CONCAT(
//         "  {\n",
//             "    label: '", label, "',\n"
//             "    value: '", `value`, "',\n"
//         '  },'
//     )
//     SEPARATOR
//     "\n"
// )
// FROM `civicrm_option_value`
// WHERE option_group_id=141