// geocoded using http://www.mapdevelopers.com/geocode_tool.php

const locations = [
  {
    label: 'Anita May Rosenstein Campus',
    address: '1118 N. McCadden Place, Los Angeles, CA 90038',
    // Latitude: 34.0911342 Longitude: -118.3373432
    position: { lat: 34.0911342, lng: -118.3373432 },
    selected: true,
    value: '3',
  },
  {
    label: 'The Village at Ed Gould Plaza',
    address: '1125 N. McCadden Place, Los Angeles, CA 90038',
    // Latitude: 34.091897 Longitude: -118.337689
    position: { lat: 34.091897, lng: -118.337689 },
    selected: false,
    value: '2',
    color: '#e67e22',
  },
  {
    label: 'Triangle Square',
    address: '1602 Ivar Ave., Los Angeles, CA 90028',
    // Latitude: 34.100172 Longitude: -118.327517
    position: { lat: 34.100172, lng: -118.327517 },
    selected: false,
    value: '7',
  },
  {
    label: 'Mi Centro',
    address: '553 S. Clarence St., Los Angeles, CA 90033',
    // Latitude: 34.039277 Longitude: -118.22349
    position: { lat: 34.039277, lng: -118.22349 },
    selected: false,
    value: '4',
  },
  // {
  //   label: 'MCC',
  //   address: '5730 Cahuenga Blvd., Los Angeles, CA 91601',
  //   // Latitude: 34.174954 Longitude: -118.361238
  //   position: { lat: 34.174954, lng: -118.361238 },
  //   // selected: false,
  //   // value: 'MCC',
  // },
  // {
  //   label: 'Highland Youth Center and Annex',
  //   address: '1220 N. Highland Ave, Los Angeles, CA 90038',
  //   // Latitude: 34.093208 Longitude: -118.33825
  //   position: { lat: 34.093208, lng: -118.33825 },
  //   selected: false,
  //   // value: 'Highland Youth Center and Annex',
  // },
  {
    label: 'McDonald/Wright Building',
    address: '1625 N. Schrader Blvd., Los Angeles, CA 90028',
    // Latitude: 34.100552 Longitude: -118.332732
    position: { lat: 34.100552, lng: -118.332732 },
    selected: false,
    value: '1',
  },
  {
    label: 'West Hollywood',
    address: '8745 Santa Monica Blvd., 2nd Floor, West Hollywood, CA 90069',
    // Latitude: 34.087234 Longitude: -118.381395
    position: { lat:  34.087234, lng: -118.381395 },
    selected: false,
    value: '5',
  },
  {
    label: 'Center South',
    address: '2313 W. Martin Luther King Jr. Blvd., Los Angeles, CA 90008',
    // Latitude: 34.010894 Longitude: -118.318334
    position: { lat: 34.010894, lng: -118.318334 },
    selected: false,
    value: '9',
    color: '#27ae60',
  },
  {
    label: 'Other',
    address: null,
    // Latitude: NA
    position: null,
    selected: false,
    value: '8',
  },
];

export default locations;

// SELECT
// GROUP_CONCAT(
//     CONCAT(
//         "  {\n",
//             "    label: '", label, "',\n"
//             "    value: '", `value`, "',\n"
//         '  },'
//     )
//     SEPARATOR
//     "\n"
// )
// FROM `civicrm_option_value`
// WHERE option_group_id=122