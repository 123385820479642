import React from 'react';
import preval from 'preval.macro';

const About = props => (
  <div className="About">
    <dl>
      <dt>Build Date</dt>
      <dd>
        {preval`
          const moment = require('moment-timezone');
          const now = moment().tz("America/Los_Angeles");
          const format = 'YYYY-MM-DD HH:mm:ss';
          module.exports = now.format(format);
        `}
      </dd>
      <dt>Favorite Dish</dt>
      <dd>Tacos</dd>
      <dt>Satisfaction Level</dt>
      <dd>Off the chart</dd>
      <dt>Seconds?</dt>
      <dd>Duh....</dd>
    </dl>
  </div>
);

export default About;