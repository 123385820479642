import React from 'react';
import { Image } from 'grommet';

import cancel from 'assets/images/help/cancel.png';
import checkin_approve from 'assets/images/help/checkin_approve.png';
import checkin_intake from 'assets/images/help/checkin_intake.png';
import checkin from 'assets/images/help/checkin.png';
import scan from 'assets/images/help/scan.png';
import username_password from 'assets/images/help/username_password.png';

const Help = props => (
  <div className="Help">
    <p><strong>Q: How do I login to the Kiosk?</strong></p>
    <p>
      <strong><em>Ans: </em></strong>
      There are two ways to login to the Kiosk.
    </p>
    <ol>
      <li>
        Please scan the QR Code found on the client's ID card, on the webcam.
        <br />
        <Image src={scan} margin="small" />
      </li>
      <li>
        When you tap on the space under 'Username' and 'Password', an on-screen keyboard will appear on the bottom right corner of the screen. You can insert the client's Username and Password and then tap on the 'Login' button.
        <br />
        <Image src={username_password} margin="small" />
      </li>
    </ol>
    <p><strong>Q: What if the client loses their ID card or forget their login information or both?</strong></p>
    <p>
      <strong><em>Ans: </em></strong>
      Please contact a Youth Center staff to print and give the client a temporary replacement ID card or provide them with their username and password.
    </p>
    <p><strong>Q: Client is new to the center. How can they check-in to the Youth Center?</strong></p>
    <p>
      <strong><em>Ans: </em></strong>
      Please have the client see a Youth Center staff at the desk to request further information on how to be a registered client of the Center.
    </p>
    <p><strong>Q: How to check-in a youth who is not restricted and has current year's intake information?</strong></p>
    <p>
      <strong><em>Ans: </em></strong>
      After scanning the youth's ID card, please tap on 'Confirm' to check-in the youth as shown below.
      <br />
      <Image src={checkin} margin="small" />
    </p>
    <p><strong>Q: How to check-in a 'Restricted' youth?</strong></p>
    <p>
      <strong><em>Ans: </em></strong>
      If you see that the youth is restricted after you scan their ID card as shown below, please reach out to one of the official approvers, who are authorized to approve the youth to get into the Youth Center. The approver can then scan their ID card to check-in the youth. Please tap on 'Cancel' to log out of the check-in page without approving the youth.
      <br />
      <Image src={checkin_approve} margin="small" />
    </p>
    <p><strong>Q: How can I check-in a youth that does not have current year's YCH intake information?</strong></p>
    <p>
      <strong><em>Ans: </em></strong>
      The Kiosk will show you that the youth will not be able to check-in if they don't have their intake information for the current fiscal year. Please notify the youth to see desk so that they can fill out their intake.
      <br />
      <Image src={checkin_intake} margin="small" />
    </p>
    <p><strong>Q: How to log out of the Kiosk?</strong></p>
    <p>
      <strong><em>Ans: </em></strong>
      Once you're ready to log out of the Kiosk, please tap on the 'Cancel' button on the top right corner as well as on the bottom of the screen as shown below.
      <br />
      <Image src={cancel} margin="small" />
    </p>
  </div>
);

export default Help;