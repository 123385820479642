import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import {
  updateCurrentState,
  updateLoadingState,
  setSingleData,
} from 'store/actions/single';
import { addMessage } from 'store/actions/messages';
import { Form, reduxForm } from 'redux-form';
import { Box, Button } from 'grommet';
import { Close } from 'grommet-icons';
import './Checkin/styles.css';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import Map from './Checkin/Map';
import StillHere from './Checkin/StillHere';
import WelcomeHeading from './Checkin/WelcomeHeading';
import * as moment from 'moment-timezone';

import ageGroups from 'helpers/Filter/ageGroups';
import transgenders from 'helpers/Filter/transgenders';
import ethnicities from 'helpers/Filter/ethnicities';
import genders from 'helpers/Filter/genders';
import householdIncomes from 'helpers/Filter/householdIncomes';
import levelsOfEducation from 'helpers/Filter/levelsOfEducation';
import technologyAccesses from 'helpers/Filter/technologyAccesses';
import locations from 'helpers/Filter/locations';

const handlers = {
  'click': null,
  'scroll': null,
  'touchstart': null,
  'touchend': null,
  'touchmove': null,
  'touchcancel': null,
  'keydown': null,
  'keyup': null,
};

class Checkin extends Component {

  constructor(props){
    super(props);
    this.state = {
      stillHereTimeout: null,
      stillHereOpen: false,
    };
  }

  componentDidMount() {
    
    this.loadData();

    // setup events to trigger still here
    this.setupStillHereTimeout();
    const handler = e => this.setupStillHereTimeout();
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] = handler;
      window.addEventListener(eventName, handler, true);
    });
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentWillUnmount() {

    if (this.state.stillHereTimeout!==null) {
      clearTimeout(this.state.stillHereTimeout);
    }

    // remove events that trigger still here
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] && window.removeEventListener(eventName, handlers[eventName], true);
    });
  }

  calculateCurrentState = () => {
    const tableState = [
      this.props.contactId,
    ].join('|');
    return tableState;
  }

  loadData = () => {
    
    const currentState = this.calculateCurrentState();
    const { state, loadingState } = this.props;
    // console.log({ currentState, state, loadingState})
    if (
      currentState === state ||
      currentState === loadingState
    ) return;

    this.props.handleUpdateLoadingState(currentState);

    const returnFields = [
      'gender_id', // gender
      'custom_151', // transgender
      'custom_144', // ethnicity
      'custom_142', // age group
      'custom_145', // education level
      'custom_146', // income level
      'custom_147', // technology access
      'custom_149', // last updated
      'custom_152', // last updated location
    ];

    const url = handleCiviURLBuild('Contact','get',{
      sequential:1,
      id: this.props.contactId,
      return: returnFields,
    });
    // console.log({ url })
    
    return fetch(url)
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      return json;
    })
    .then(data => data.values[0])
    .then(data => {
      // civicrm is inconsistant in returning variables
      returnFields.forEach(field => {
        if (!(field in data))
          data[field] = '';
      });

      const {
        gender_id, // gender
        custom_151, // transgender
        custom_144, // ethnicity
        custom_142, // age group
        custom_145, // education level
        custom_146, // income level
        custom_147, // technology access
        custom_149, // last updated
        custom_152, // last updated location
       } = data;

      const gender = genders.find(i => i.value===gender_id);
      data.gender = gender ? gender.label : null;

      const transgender = transgenders.find(i => i.value===custom_151);
      data.transgender = transgender ? transgender.label : null;

      const ethnicity = ethnicities.find(i => i.value===custom_144);
      data.ethnicity = ethnicity ? ethnicity.label : null;

      const ageGroup = ageGroups.find(i => i.value===custom_142);
      data.ageGroup = ageGroup ? ageGroup.label : null;

      const levelOfEducation = levelsOfEducation.find(i => i.value===custom_145);
      data.levelOfEducation = levelOfEducation ? levelOfEducation.label : null;

      const householdIncome = householdIncomes.find(i => i.value===custom_146);
      data.householdIncome = householdIncome ? householdIncome.label : null;

      const technologyAccess = technologyAccesses.filter(i => custom_147 && custom_147.includes(i.value));
      data.technologyAccess = technologyAccess ? technologyAccess.map(i => i.label).join(', ') : null;

      const now = moment().tz("America/Los_Angeles");
      console.log(now.format('MMMM Do, YYYY'))

      const lastUpdated = custom_149 ? moment.tz(custom_149, 'YYYY-MM-DD', "America/Los_Angeles") : null;
      data.lastUpdated = lastUpdated ? lastUpdated.format('MMMM Do, YYYY') : null;
      data.lastUpdatedOld = lastUpdated ? lastUpdated.isBefore(now.subtract(1, 'year')) : true;
      console.log(now.format('MMMM Do, YYYY'))

      const lastUpdateLocation = locations.find(i => i.value===custom_152);
      data.lastUpdateLocation = lastUpdateLocation ? lastUpdateLocation.label : null;

      return data;
    })
    .then(data => {
      // update list data
      this.props.handleUpdateCurrentState(currentState);
      this.props.handleSetSingleData(data);
      this.props.handleUpdateLoadingState('');
    })
    .catch(e => {
      this.props.handleMessage(e.message, 'error');
    });
    
  }

  submitForm = (values) => {
    const { contactId } = this.props;
    alert(`blah! ${contactId}`);
  }

  setupStillHereTimeout = () => {

    if (this.state.stillHereTimeout!==null) {
      clearTimeout(this.state.stillHereTimeout);
    }

    const stillHereTimeout = setTimeout(() => this.toggleStillHere(), 60000); // 60 seconds
    this.setState({ stillHereTimeout });
  }

  toggleStillHere = (state=null) => {

    const stillHereOpen = state===null ? !this.state.stillHereOpen : state;
    if (!stillHereOpen) {
      this.setupStillHereTimeout();
    }
    this.setState({ stillHereOpen });
  }

  render() {

    const { contact, extraContactData, handleLogout, handleSubmit, isFetching } = this.props;
    const { stillHereOpen } = this.state;

    const { image_URL } = contact;
    let contactImage = null;
    const imageContainerStyles = {};
    if (image_URL) {
      const elem = document.createElement('textarea');
      elem.innerHTML = image_URL;
      contactImage = elem.value;
      imageContainerStyles.backgroundImage = `url('${contactImage}')`;
    }

    return (
      <Box className="Checkin" direction='column' flex align="center" justify="center">
        <Box className="headingbox" basis="15%">
          <WelcomeHeading />
        </Box>
        <Box className="clientbox" basis="80%" border={{ size: 'medium' }} pad="medium">
          {extraContactData==null ? 
            <Box>Loading...</Box>
          : (
            <Box direction="row" fill>
              <Box basis="40%" direction='column' fill margin={{ right: 'small' }} justify="center" align="center">
                {contactImage ? <Box className="clientimagebox" style={imageContainerStyles} fill></Box> : <Box>-- No Image --</Box> }
              </Box>
              <Box basis="60%" margin={{ left: 'small' }} pad={{ left: 'medium', top: 'medium' }}>
                <Form onSubmit={handleSubmit(this.submitForm)}>
                  {!isFetching &&
                    <>
                      {/*
                        'gender_id', // gender
                        'custom_151', // transgender
                        'custom_144', // ethnicity
                        'custom_142', // age group
                        'custom_145', // education level
                        'custom_146', // income level
                        'custom_147', // technology access
                        'custom_149', // last updated
                        'custom_145', // last update lcoation
                      */}
                      <Box direction="row" border="bottom" background="accent-1">
                        <Box basis="1/2" pad="xsmall"><strong>Access Code</strong></Box>
                        <Box basis="1/2" pad="xsmall">-- coming soon --</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>ID #</strong></Box>
                        <Box basis="1/2" pad="xsmall">{contact.id}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Name</strong></Box>
                        <Box basis="1/2" pad="xsmall">{contact.nick_name || contact.first_name} {contact.last_name}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Email</strong></Box>
                        <Box basis="1/2" pad="xsmall">{contact.email}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Gender</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.gender}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Transgender</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.transgender}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Ethnicity</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.ethnicity}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Age Group</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.ageGroup}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Education Level</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.levelOfEducation}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Household Income</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.householdIncome}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Technology Access</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.technologyAccess}</Box>
                      </Box>
                      <Box direction="row" border="bottom" background={extraContactData.lastUpdatedOld ? "status-critical" : "transparent"}>
                        <Box basis="1/2" pad="xsmall"><strong>Last Updated</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.lastUpdated}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Last Update Location</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.lastUpdateLocation}</Box>
                      </Box>
                    </>
                  }
                  <Box direction="row" justify="center" align="center">
                    <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                      <Button margin="none" label="Done" icon={<Close />} onClick={() => handleLogout()} />
                    </Box>
                  </Box>
                </Form>
              </Box>
            </Box>
          )}
        </Box>
        <Map />
        <StillHere open={stillHereOpen} toggle={this.toggleStillHere} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { 
    auth: { contact, contactId },
    display: { mobileMode },
    single: { extraContact },
  } = state;

  const {
    isFetching,
    data,
    loadingState,
  } = extraContact;
  
  return {
    contact,
    contactId,
    mobileMode,

    isFetching,
    extraContactData: data,
    state: extraContact.state,
    loadingState,
  };
}

const mapDispatchToProps = dispatch => {

  return {
    handleLogout: () => dispatch(logout()),
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    handleUpdateCurrentState: state => dispatch(updateCurrentState('extraContact', state)),
    handleUpdateLoadingState: loadingState => dispatch(updateLoadingState('extraContact', loadingState)),
    handleSetSingleData: data => dispatch(setSingleData('extraContact', data)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'checkin',
  })(Checkin)
);
