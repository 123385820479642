const ethnicities = [
  {
    label: 'African American/Black',
    value: '1',
  },
  {
    label: 'Asian/Pacific Islander',
    value: '2',
  },
  {
    label: 'Caucasian/White',
    value: '3',
  },
  {
    label: 'Latino/a/x',
    value: '4',
  },
  {
    label: 'Native American',
    value: '5',
  },
  {
    label: 'Bi-racial/Multi-racial',
    value: '6',
  },
  {
    label: 'Prefer not to disclose',
    value: '7',
  },
  {
    label: 'Other',
    value: '8',
  },
];

export default ethnicities;

// SELECT
// GROUP_CONCAT(
//     CONCAT(
//         "  {\n",
//             "    label: '", label, "',\n"
//             "    value: '", `value`, "',\n"
//         '  },'
//     )
//     SEPARATOR
//     "\n"
// )
// FROM `civicrm_option_value`
// WHERE option_group_id=120