// https://github.com/grommet/grommet/issues/1280#issuecomment-317194412

import React from 'react';
import { FormField } from 'grommet';
import { 
  CheckBox,
  Select,
  RadioButton,
  // RadioButtonGroup,
  Text,
  TextArea,
  TextInput
} from 'grommet';
// import { RadioButton } from './Fields/RadioButton';
import { RadioButtonGroup } from './Fields/RadioButtonGroup';

export const renderFormField = ({ 
  input,
  id,
  label,
  help,
  // onChange,
  type,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <FormField
      label={label}
      htmlFor={label}
      help={help}
      // onChange={onChange}
      error={touched && error}
      {...custom}
    >
      <TextInput id={id} type={type} {...input} />
    </FormField>
  );
}

export const renderFormFieldTextArea = ({ input, id, label, htmlfor, help, meta: { touched, error }, ...custom }) => {
  return (
    <FormField
      label={label}
      help={help}
      htmlFor={htmlfor}
      error={touched && error}
      {...custom}
    >
      <TextArea id={id} {...input} />
    </FormField>
  );
}

export const renderCheckBox = ({ 
  input: { onChange, name, value }, 
  id, 
  label, 
  inputValue,
  meta: { touched, error },
  ...custom 
}) => {
  // console.log({ input, custom })
  return (
    <CheckBox 
      id={id}
      label={label}
      name={name}
      error={touched && error}
      checked={value===true}
      onChange={onChange}
      // {...input}
      {...custom}
    />
  );
}

export const renderRadioButton = ({ input: { onChange, name, value }, id, label, inputValue, meta: { touched, error }, ...custom }) => {
  // console.log({ inputProps, custom })
  // console.log({ value, inputValue, custom })
  return (
    <div>
      {touched && error && <Text size="large" color="status-critical">{error}</Text>}
      <RadioButton 
        id={id}
        label={label}
        name={name}
        checked={inputValue ? value===inputValue : !!value}
        onChange={e => {
          if (inputValue)
            e.target.value = inputValue;
          onChange(e);
        }}
        // {...inputProps}
        {...custom}
      />
    </div>
  );
}

export const renderRadioButtonGroup = ({ input: { onChange, name, value }, id, label, options, meta: { touched, error }, ...custom }) => {
  // console.log({ custom })
  return (
    <div>
      {touched && error && <Text size="large" color="status-critical">{error}</Text>}
      <RadioButtonGroup 
        id={id}
        label={label}
        name={name}
        error={touched && error}
        value={value}
        onChange={onChange}
        options={options}
        // {...input} // dont do this... the onFocus messes everything up
        {...custom}
      />
    </div>
  );
}

export const renderSelectField = ({ 
  input: { value, onChange, ...inputProps },
  id,
  name,
  label,
  help,
  onChange: customOnChange,
  options,
  multiple,
  placeholder = <>&nbsp;</>,
  meta: { touched, error },
  ...custom 
}) => {
  
  const selectedOption = options.find(option => option.value === value);
  const valueLabel =
    <div style={{padding: 10}}>
      {selectedOption ? selectedOption.label : placeholder}
    </div>;

  return (
    <FormField
      label={label}
      htmlFor={label}
      help={help}
      error={touched && error}
      {...custom}
    >
      <Select
        id={id}
        options={options}
        name={name}
        multiple={multiple}
        value={value}
        onChange={
          value => {
            // if (multiple) {
            //   const values = value.value.map(selected => selected.value);
            //   console.log(values);
            //   onChange(values);
            // } else {
              const parsedValue = typeof value === 'object' ? value.value : value;
              if (typeof parsedValue === 'object') {
                onChange(parsedValue.value);
              } else {
                onChange(parsedValue);
              }
            // }
            if (customOnChange) 
              customOnChange(value);
          }
        }
        children={option => (
          <div style={{ padding: 10 }}>{option.label}</div>
        )}
        valueKey={option => option.value}
        valueLabel={valueLabel}
        {...inputProps}
      />
    </FormField>
  );
}
